import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './approve-registration-code.scss';
import { useDispatch, useSelector } from 'react-redux';
import { approvRegistrationCode } from '../../../features/approve-registration-code/approve-registration-code-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import UserIcon from '../../../assets/icons/user-icon';
import MainLayout from '../../layouts/mainLayout';
import ErrorComponent from '../../ui/error/error-component';
import { repeatConfirmCode } from '../../../features/register/authSlice';
import { loginUser } from '../../../features/login/loginSlice';
import Spinner from '../../ui/spinner/spinner';
import CloseIcon from '../../../assets/icons/closeicon.svg'

const ApproveRegistrationCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const [error, setError] = useState(false);
  const isLoading = useSelector(
    (state: any) => state?.approveRegister.isLoading
  );
  const [codeModal, setCodeModal] = useState(false)

  const handleCloseCodeModal = () => {
    setCodeModal(false)
  }

  const handleClick = () => {
    navigate('/login');
  };

  const [code, setCode] = useState(new Array(6).fill(''));

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, code.length);
  }, [code]);

  const user = useSelector((state: any) => state.auth.user) || false;
  const registerEmail = user?.email;

  const handleRepeatCode = () => {
    try {
      // @ts-ignore
      dispatch(repeatConfirmCode(registerEmail));
      setCodeModal(true)
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (index: any, value: any) => {
    console.log('handleChange called', index, value);
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
  
    if (value.length === 1 && index < code.length - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        // @ts-ignore
        nextInput.focus();
      }
    }
  
    if (newCode.every((val) => val.length === 1)) {
      const confirmationCode = newCode.join('');
      const numericCode = parseInt(confirmationCode, 10);
  
      const userData = {
        confirmationCode: numericCode,
      };
  
      try {
        // @ts-ignore
        const result = await dispatch(approvRegistrationCode(userData)).then(unwrapResult);
        console.log('approvRegistrationCode result:', result);
  
        const email = result?.email;
        const password = result?.password;
  
        if (email && password) {
          const loginData = {
            email,
            password,
          };
  
          // @ts-ignore
          const loginResult = await dispatch(loginUser(loginData)).then(unwrapResult);
          console.log('loginUser result:', loginResult);
  
          if (loginResult) {
            navigate('/success-registration-code');
          } else {
            alert('Login error');
          }
        } else {
          alert('Invalid response from approvRegistrationCode');
        }
      } catch (error: any) {
        console.error('Error:', error);
        setError(true);
      }
    }
  };
  

  const handlePaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const pasteArray = paste.slice(0, 6).split('');

    const newCode = [...code];
    for (let i = 0; i < pasteArray.length; i++) {
      newCode[i] = pasteArray[i];
      if (inputRefs.current[i]) {
        // @ts-ignore
        inputRefs.current[i].value = pasteArray[i];
      }
    }
    setCode(newCode);

    if (pasteArray.length === 6) {
      const confirmationCode = newCode.join('');
      const numericCode = parseInt(confirmationCode, 10);

      const userData = {
        confirmationCode: numericCode,
      };

      // @ts-ignore
      dispatch(approvRegistrationCode(userData))
        .then(unwrapResult)
        .then((result: any) => {
          const email = result?.email;
          const password = result?.password;
          const userData = {
            email,
            password,
          };

         // @ts-ignore
          dispatch(loginUser(userData))
            .then(unwrapResult)
            .then((result: any) => {
              if (result) {
                navigate('/success-registration-code');
              }
            });
        })
        .catch((error: any) => {
          setError(true);
        });
    } else {
      const nextInput = inputRefs.current[pasteArray.length];
      if (nextInput) {
        // @ts-ignore
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e:any, index:any) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (e.key === 'Backspace' && e.target.value === '' && index > 0) {
        // Move focus to the previous input if Backspace is pressed and current input is empty
        // @ts-ignore
        inputRefs.current[index - 1].focus();
      } else if (e.key === 'Delete' && e.target.value === '' && index < code.length - 1) {
        // Move focus to the next input if Delete is pressed and current input is empty
        // @ts-ignore
        inputRefs.current[index + 1].focus();
      }
    }
  };

  return (
    <MainLayout>
            {codeModal && <div className='add-balance__modal-container'>
          <div className='add-balance__modal-container__main-box  approve-code-modal'>
            <div>
            <p className='check-email'>Проверьте почту</p>
            <p className='code-sended'>Код повторно отправлен на вашу почту.</p>
            </div>

            <img src={CloseIcon} alt='CloseIcon' width={24} height={24} className='code-close-icon-img' onClick={handleCloseCodeModal} />
            </div>
          </div>}
      <div className='recovery-container'>
        <div className='approve-recovery-main approve-registration-box'>
          <div className='approve-registration-box--container'>
            {isLoading && (
              <div
                className={
                  'approve-registration-box--container__absolute-layer'
                }
              >
                <Spinner />
              </div>
            )}
            <p className='recovery-title approve-registration-title'>
              Подтверждение регистрации
            </p>
            <div className='approve-registration-input-section'>
              {code.map((_, index) => (
                <input
                  className='approve-registration-input'
                  key={index}
                  type='text'
                  name={`code${index + 1}`}
                  // @ts-ignore
                  maxLength='1'
                  value={code[index]}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  // @ts-ignore
                  ref={(el) => (inputRefs.current[index] = el)}
                  required
                />
              ))}
            </div>
            {error && <ErrorComponent error='Некорректный код' />}

            <p className='approve-registration'>
              Введите код отправленный вам на почту <br /> для завершения
              регистрации
            </p>

            <div className='approve-recovery-main__send-again'>
              <div className='repeat-code' onClick={handleRepeatCode}>
                Отправить код повторно
              </div>
            </div>
          </div>
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText={'Зарегистрироваться'}
            widtIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ApproveRegistrationCode;
